import {
  Box,
  ChakraProps,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  usePrefersReducedMotion,
} from "@biblioteksentralen/react";
import { randomFromArray } from "@biblioteksentralen/utils";
import { digitalLibraryServiceCategoryIcons } from "@libry-content/common";
import { range } from "radash";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { DefaultContainer } from "../../ContentContainer";
import SeMerLenke from "../SeMerLenke";
import { FrontPageData } from "../../../app/[locale]/sites/[site]/page";

type Props = Pick<FrontPageData, "digitalLibraryServices">;

export const DigitalServicesBanner = (props: Props) => {
  const { t } = useTranslation();
  const headingId = useId();
  const palette = useSitePalette();

  if (!props.digitalLibraryServices.length) return null;

  return (
    <DefaultContainer withoutPaddingXBellow="md" as="section" aria-labelledby={headingId}>
      <Stack {...palette.colors.lightaccent3.css} borderRadius={{ md: "lg" }}>
        <Stack padding={{ base: "1.5rem .75rem .75rem", sm: "2rem 2rem 1.25rem" }}>
          <Heading as="h2" size="lg" id={headingId}>
            {t("Sjekk ut vårt digitale bibliotek")}
          </Heading>
          <Text fontSize="lg">{t("Vi har mye mer enn bøker. Helt gratis!")}</Text>
        </Stack>
        <Decoration height={{ base: "11rem", sm: "12rem" }} digitalLibraryServices={props.digitalLibraryServices} />
        <Box padding={{ base: "1.25rem .75rem 1.5rem", sm: "1.5rem 2rem 2rem" }} alignSelf="flex-end">
          <SeMerLenke href="/digitalt-bibliotek" marginTop="-.5rem">
            {t("Gå til digitalt bibliotek")}
          </SeMerLenke>
        </Box>
      </Stack>
    </DefaultContainer>
  );
};

const Decoration = ({ digitalLibraryServices, ...chakraProps }: Props & ChakraProps) => {
  const items = extendArray(digitalLibraryServices, 25);
  const [currentHighlightedIndex, setCurrentHighlightedIndex] = useState<number>(0);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const possibleNextIndexes = Array.from(range(items.length)).filter((value) => value !== currentHighlightedIndex);
    const timeout = setTimeout(
      () => setCurrentHighlightedIndex(randomFromArray(possibleNextIndexes)),
      Math.random() * 200 + 200
    );
    return () => clearTimeout(timeout);
  }, [items, currentHighlightedIndex]);

  return (
    <Box
      aria-hidden={true} // This is purley decorative, and would probably be noisy to screen readers
      fontSize={{ base: ".75rem", sm: "1.25rem" }}
      overflow="hidden"
    >
      <HStack gap=".5em" width="calc(100% + 20em)" marginLeft="-5em" flexWrap="wrap" {...chakraProps}>
        {items.map((service, i) => (
          <Item
            key={service._id + i}
            service={service}
            isHighlighted={!prefersReducedMotion && currentHighlightedIndex === i}
          />
        ))}
      </HStack>
    </Box>
  );
};

const Item = (props: { service: Props["digitalLibraryServices"][number]; isHighlighted: boolean }) => {
  const { ts } = useTranslation();

  return (
    <HStack
      padding=".75em 1.25em"
      borderRadius=".5em"
      alignItems="center"
      position="relative"
      overflow="hidden"
      isolation="isolate"
      transition={props.isHighlighted ? ".2s" : "2s"}
      transitionDelay={props.isHighlighted ? "0" : "1s"}
      background={props.isHighlighted ? "rgba(0,0,0,.1)" : undefined}
    >
      <Icon
        zIndex={1}
        fontSize="1.25em"
        as={digitalLibraryServiceCategoryIcons[props.service.category ?? "other-digital-resources"]}
      />
      <Text fontSize="sm" fontWeight={600} zIndex={1}>
        {ts(props.service.name)}
      </Text>
    </HStack>
  );
};

// Extends an array by duplicating the source array until the desired size is reached
const extendArray = <T,>(array: T[], size: number): T[] => {
  const newArray = [...array, ...array];
  if (newArray.length > size) return newArray.slice(0, size);
  return extendArray(newArray, size);
};
