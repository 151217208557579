import { isCampaignSeason } from "@libry-content/common";
import dynamic from "next/dynamic";
import { getPath } from "../../utils/getPath";
import { TranslateMicroText, useTranslation } from "../../utils/hooks/useTranslation";
import { ResolvedAdventCalendarBannerData } from "./sanityQuery";

// Bare laste inn denne koden når den trengs
const DynamicAdventCalendarBanner = dynamic(() => import("./AdventCalendarBanner"));

const getAdventCalendarBannerTitle = (t: TranslateMicroText, isFinished: boolean) =>
  isFinished ? t("Fikk du med deg julekalenderen vår?") : t("Årets julekalender");

// TODO: Update finished banner text?
const getAdventCalendarBannerText = (t: TranslateMicroText, isFinished: boolean) =>
  isFinished
    ? `${t("Julekalenderen er tilgjengelig ut desember.")} ${t("Åpne lukene for å se hva som skjuler seg bak.")}`
    : t("Kom i julestemning med bibliotekets julekalender og se hva som skjuler seg bak lukene");

export const FrontpageAdventCalendarBanner = (props: { adventCalendar: ResolvedAdventCalendarBannerData | null }) => {
  const adventCalendarFinished =
    !!props.adventCalendar && isCampaignSeason(props.adventCalendar) && new Date().getDate() >= 24;

  const { t } = useTranslation();

  if (!props.adventCalendar) return null;

  return (
    <DynamicAdventCalendarBanner
      adventCalendar={props.adventCalendar}
      href={getPath(props.adventCalendar)}
      title={getAdventCalendarBannerTitle(t, adventCalendarFinished)}
      text={getAdventCalendarBannerText(t, adventCalendarFinished)}
    />
  );
};
