import { Box, Stack } from "@biblioteksentralen/react";
import { FrontPageData } from "../../app/[locale]/sites/[site]/page";
import AlertBanner from "./banners/alert/AlertBanner";
import BookListBanner from "./banners/BookListBanner";
import { DigitalServicesBanner } from "./banners/DigitalServicesBanner";
import EventsBanner from "./banners/events/EventsBanner";
import { LibrariesBanner } from "./banners/LibrariesBanner";
import { OpeningHoursBanner } from "./banners/openingHours/OpeningHoursBanner";
import { useFeaturedBanners } from "./banners/promotedItems/FeaturedBanner";
import { ServicesBanner } from "./banners/ServicesBanner";

/**
 * NOTE: Update docs when making changes: https://bs-redia.atlassian.net/l/cp/e54CX5CS
 */
export function SiteView(props: FrontPageData) {
  const { firstFeaturedBanner, secondFeaturedBanner, removeFeaturedItems } = useFeaturedBanners(props);

  return (
    <Box padding={{ lg: "3rem 0 0" }}>
      <AlertBanner alert={props.alert} marginBottom={{ base: "2rem", lg: "3rem" }} />
      <Stack direction="column" spacing={{ base: "3rem", lg: "5rem" }}>
        {firstFeaturedBanner}
        <OpeningHoursBanner libraries={props.libraries} />
        {secondFeaturedBanner}
        <EventsBanner events={props.events} />
        <DigitalServicesBanner digitalLibraryServices={props.digitalLibraryServices} />
        <BookListBanner listAndRecommendations={removeFeaturedItems(props.listsAndRecommendations).slice(0, 3)} />
        <ServicesBanner services={props.services} />
        <BookListBanner listAndRecommendations={removeFeaturedItems(props.listsAndRecommendations).slice(3, 6)} />
        <LibrariesBanner libraries={props.libraries} />
      </Stack>
    </Box>
  );
}
